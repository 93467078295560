import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Modal, Row} from 'react-bootstrap';
import {BurntSienna, ColorBg, hoverNd} from '../../styles/globalStyleVars';
import {BsCircleFill} from "react-icons/bs";
import Button from "../Button";
import {components} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import ReactHtmlParser from "react-html-parser";
import {Link, useHistory, useLocation} from "react-router-dom";

const MyComponent = ({queryParam,loc,data}) => {
    const [activeIndex, setActiveIndex] = useState(0); // Default active index set to 0
    const [selectedLocation, setSelectedLocation] = useState('');



    const handleClick = (index,label) => {
        setActiveIndex(index); // Update the active index when an item is clicked
        setSelectedLocation(label);
    };


    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        history.push({
            // pathname: '/projects',
            // search: `?location=${element?.office_location}&jobid=${element?.ID}`
            search: `?jobid=`
        });
    };

    const handleShow = () => setShow(true);

    //get the query
    const locations = useLocation();
    const jobIdfromParam = new URLSearchParams(locations?.search).get("jobid");

    const [selectedJobTitle, setSelectedJobTitle] = useState('');
    const [popupData, setPopupData] = useState('');
    const [jobId, setJobId] = useState('');
    let history=useHistory();

    const handlePopup = (element) => {
        setPopupData(element)
        setShow(true);
        setJobId(element?.ID)
        history.push({
            // pathname: '/projects',
            // search: `?location=${element?.office_location}&jobid=${element?.ID}`
            search: `?location=${element?.office_location}&jobid=${element?.ID}`
        });
    };
    const [selectedFileName, setSelectedFileName] = useState('');
    const [cv, setCv] = useState(null)

    const handleFileChange = (event) => {
        setCv(event.target.files[0])
        const file = event.target.files[0];
        setSelectedFileName(file.name);
    };

    useEffect(() => {
        let getLists = document.querySelector('.desktop-lists');
        let getLi = getLists.querySelectorAll('li');
        if (typeof (queryParam) === 'undefined') {
            getLi[0]?.classList.add('active')
        } else {
            getLi[0]?.classList.remove('active')
        }
    }, [queryParam])

    const optionsOne = [
        {value: 'Dhaka', label: 'Dhaka'},
        {value: 'Chattogram', label: 'Chattogram'},

    ]
    let handleMobileSelect = (location) => {
        setSelectedLocation(location);
    }


    let firstLocation= loc?.[0]?.label;

    let filterPostBySlug = selectedLocation === '' ? data?.filter(element => element?.office_location === firstLocation) : data?.filter(element => element?.office_location === selectedLocation);

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>;
    };


    //Form submission

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });
    const onSubmit = (e) => {
        let api_services = apiEndPoints.APPLY_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('number-142', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('file-971', cv);
        formData.append('your-sent-email',popupData?.dedicated_email);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
        setSelectedFileName('');
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])

    useEffect(() => {
        // Check if jobIdfromParam exists in the URL
        if (jobIdfromParam) {
            const filterData=data?.find((element)=>element?.ID==jobIdfromParam)
            setPopupData(filterData);
            setShow(true);
            // setJobId(jobToShow?.ID);
        }

    }, [jobIdfromParam, data]); // Dependencies include jobIdfromParam and data


    return (
        <StyledMyComponent className={''}>
            <Container>
                <Row>
                    <Col md={4}>
                        <div className={'desktop-lists'}>
                            <ul>
                                {
                                    loc && loc.length>0 &&
                                    loc.map((element,index)=>{
                                        return (
                                            <li
                                                key={index}
                                                className={activeIndex === index ? 'active' : ''}
                                                onClick={() => handleClick(index,element?.label)} // Set active on click
                                            >
                                                {element?.label} <BsCircleFill/>
                                            </li>
                                        )

                                    })
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col md={8}>
                        {
                            filterPostBySlug  && filterPostBySlug.length>0 &&
                            filterPostBySlug.map((element,index)=>{
                                return (
                                    <div key={index} className={'job-single'} onClick={() => handlePopup(element)}>
                                        <h5>{element?.job_title}</h5>
                                        <Button text={'Apply'} fontWeight={'700'} background={'#181D24'}
                                                hoverBackground={'#897059'}
                                                color={'#FFFDFB'}/>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
            </Container>

            <Modal show={show} className='apply-modal' onHide={handleClose}>
                <Modal.Body>
                    <div className="modal-data">
                        <Container>
                            <Row>
                                <Col md={{span: 7}}>
                                    <div onClick={handleClose} className="modal-close mobile dc-btn">
                                        <svg id="Button_-_Close" data-name="Button - Close"
                                             xmlns="http://www.w3.org/2000/svg" width="70" height="70"
                                             viewBox="0 0 70 70">
                                            <g id="Ellipse_10" data-name="Ellipse 10"
                                               transform="translate(70 70) rotate(180)" fill="transparent"
                                               stroke="#FFFDFB" stroke-linecap="round" stroke-width="1">
                                                <circle id={'circle-border'} cx="35" cy="35" r="35" stroke="#181D24"
                                                        stroke-width="2"/>
                                                <circle id={'hover-color'} cx="35" cy="35" r="34.5" fill="none"
                                                        stroke-width="0"/>
                                            </g>
                                            <circle id="Ellipse_470" data-name="Ellipse 470" cx="2" cy="2" r="2"
                                                    transform="translate(37 37) rotate(180)" fill="#181D24"
                                                    opacity="0"/>
                                            <g id="Group_1433" data-name="Group 1433"
                                               transform="translate(-107 -18)">
                                                <line id="Line_4" data-name="Line 4" x2="24.021"
                                                      transform="translate(133.515 44.515) rotate(45)" fill="none"
                                                      stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_3" data-name="Line 3" x2="24.021"
                                                      transform="translate(150.485 44.5) rotate(135)" fill="none"
                                                      stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <SimpleBar className="custombar" style={{height: '85vh'}}>
                                        <h6>{popupData?.job_title}</h6>
                                        <div className="left_col__content">
                                            <div className="content ">
                                                {
                                                    ReactHtmlParser(popupData?.job_description)
                                                }
                                            </div>
                                        </div>
                                    </SimpleBar>
                                </Col>
                                <Col md={{span: 4, offset: 1}} className='modal-data__content'>
                                    <div onClick={handleClose} className="modal-close desktop-v dc-btn">
                                        <svg id="Button_-_Close" data-name="Button - Close"
                                             xmlns="http://www.w3.org/2000/svg" width="70" height="70"
                                             viewBox="0 0 70 70">
                                            <g id="Ellipse_10" data-name="Ellipse 10"
                                               transform="translate(70 70) rotate(180)" fill="transparent"
                                               stroke="#FFFDFB" stroke-linecap="round" stroke-width="1">
                                                <circle id={'circle-border'} cx="35" cy="35" r="35" stroke="#181D24"
                                                        stroke-width="2"/>
                                                <circle id={'hover-color'} cx="35" cy="35" r="34.5" fill="none"
                                                        stroke-width="0"/>
                                            </g>
                                            <circle id="Ellipse_470" data-name="Ellipse 470" cx="2" cy="2" r="2"
                                                    transform="translate(37 37) rotate(180)" fill="#181D24"
                                                    opacity="0"/>
                                            <g id="Group_1433" data-name="Group 1433"
                                               transform="translate(-107 -18)">
                                                <line id="Line_4" data-name="Line 4" x2="24.021"
                                                      transform="translate(133.515 44.515) rotate(45)" fill="none"
                                                      stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_3" data-name="Line 3" x2="24.021"
                                                      transform="translate(150.485 44.5) rotate(135)" fill="none"
                                                      stroke="#181D24" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <h5>Apply Job</h5>

                                    <Form>
                                        <Form.Control
                                            className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("name", {
                                                required: 'Username is required',

                                            })}
                                            type="text" placeholder="Full Name*"/>
                                        <Form.Control
                                            className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("phone", {
                                                required: {
                                                    value: true,
                                                    message: 'please enter your phone first'
                                                },
                                                pattern: {
                                                    value: /^01[0-9]{9}$/,
                                                    message: 'please enter a valid 11 digit phone number'
                                                }
                                            })}
                                            type="number" placeholder="Phone Number*"/>
                                        <Form.Control
                                            className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: 'please enter your email'
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'please enter a valid email address'
                                                }
                                            })}
                                            type="email" placeholder="Email*"/>
                                        <Form.Control
                                            className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("message", {
                                                required: {
                                                    // value:true,
                                                    message: 'please enter your message'
                                                },

                                            })}
                                            type="textarea" placeholder="Message"/>

                                        <div className="button-group">
                                            <div className="attachCvName">
                                                <div className="attach-cv">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26.268" height="24"
                                                         viewBox="0 0 26.268 24"
                                                         style={{display: selectedFileName ? "none" : "block"}}>
                                                        <g id="Group_15866" data-name="Group 15866"
                                                           transform="translate(-12252 -863)">
                                                            <path id="Path_6994" data-name="Path 6994"
                                                                  d="M129.092,23.561a4.6,4.6,0,0,0-3.3-1.463h-.057a4.7,4.7,0,0,0-3.2,1.384l-.009.008L110.8,35.42a2.822,2.822,0,0,0,0,4.1,2.878,2.878,0,0,0,2.044.892h0a2.9,2.9,0,0,0,2.061-.9l8.11-8.285a1.026,1.026,0,0,0-1.466-1.435l-8.106,8.281a.862.862,0,0,1-.6.283.828.828,0,0,1-.586-.283.791.791,0,0,1,0-1.212l11.718-11.914a2.444,2.444,0,0,1,3.658.058,2.571,2.571,0,0,1,.019,3.809,1.026,1.026,0,1,0,1.458,1.443,4.6,4.6,0,0,0-.019-6.695Z"
                                                                  transform="translate(12147.724 840.902)"
                                                                  fill="#181D24"/>
                                                            <path id="Path_6995" data-name="Path 6995"
                                                                  d="M21.594,71.964a1.026,1.026,0,0,0-1.45.023L10.019,82.444a4.609,4.609,0,0,1-3.266,1.435h0A4.56,4.56,0,0,1,3.49,82.418a4.374,4.374,0,0,1,0-6.476L13.6,65.836a1.026,1.026,0,0,0-1.45-1.451L2.037,74.493l0,0A6.745,6.745,0,0,0,0,79.046a6.537,6.537,0,0,0,2.029,4.814,6.653,6.653,0,0,0,4.721,2.07h0a6.644,6.644,0,0,0,4.728-2.048L21.617,73.414A1.026,1.026,0,0,0,21.594,71.964Z"
                                                                  transform="translate(12252 801.07)" fill="#181D24"/>
                                                        </g>
                                                    </svg>
                                                    <Form.Control
                                                        {...register("file", {
                                                            required: 'Please attach a file',
                                                        })}
                                                        type="file"
                                                        accept=".pdf"
                                                        id="resume-upload"
                                                        style={{display: "none"}}
                                                        onChange={handleFileChange}
                                                    />
                                                    <Form.Label htmlFor="resume-upload" className="resume-upload-label"
                                                                style={{display: selectedFileName ? "none" : "block"}}>
                                                        Attach Resume
                                                    </Form.Label>
                                                    {selectedFileName && (
                                                        <div className="file-name">
                                                            {selectedFileName}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div onClick={handleSubmit(onSubmit, onError)}>
                                                <Button text={'Submit'} fontWeight={'700'} background={'#897059'}
                                                        hoverBackground={'#181D24'} color={'#FFFDFB'}/>
                                            </div>
                                        </div>
                                    </Form>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>

        </StyledMyComponent>
    );
};

const StyledMyComponent = styled.section`
    
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: ${ColorBg};
    .desktop-lists {
        ul {
            @media (max-width: 550px) {
                display: none;
            }

            li {
                margin-bottom: 10px;
                cursor: pointer;
                display: flex;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                color: #B2A89F;
                font-family: "Oregon LDO";
                width: fit-content;
                svg{
                    width: 10px;
                    height: 10px;
                    margin-left: 10px;
                    display: none;
                    margin-top: 6px;
                    fill:${BurntSienna};
                }
               
                a {
                    
                }

                &.active {
                    color: #897059;
                    svg{
                        display: block;
                    }
                    
                }
            }
        }
    }
    .job-single {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        h5 {
            font-weight: 400;
            color: #897059;
            margin-right: 20%;
        }
        border-bottom: 1px solid #B2A89F; /* Adds border only to items that are NOT the last child */
        padding-bottom: 20px;
        //
        //&:not(:last-child) {
        //}

        &:not(:first-child) {
            margin-top: 30px; /* Adds margin only to the first child */
        }
    }
    
    
    @media(max-width: 767px){
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .main_scroll{
        display: none !important;
    }

`;

export default MyComponent;
