import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import AtGlance from "../../components/project-details/AtaGlance";
import FeatureSlider from "../../components/project-details/FeatureSlider";
import ProjectGallery from "../../components/project-details/ProjectGallery";
import Video from "../../components/Video";
import ProjectForm from "../../components/project-details/ProjectForm";
import Overview from "../../components/project-details/Overview";
import {fetchProjectDetail} from "../../api/redux/project";
import InnerBanner from "../../components/InnerBanner";
import VirtualTour from "../../components/project-details/VirtualTour";
import VideoPopUp from "../../components/VideoPopUp";
import ProjectMap from "../../components/project-details/ProjectMap";
import InnerBannerV2 from "../../components/InnerBannerV2";
import BannerDetail from "../../components/BannerDetail";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.project)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECT_DETAIL
        dispatch(fetchProjectDetail([api_url + `/${slug}`]))
    }, [])

    const projectDetailsBanner = getData?.detail?.[0]?.banner;
    const projectOverview = getData?.detail?.[0]?.overview;
    const projectGlance = getData?.detail?.[0]?.at_a_glance;
    const projectFeature = getData?.detail?.[0]?.feature;
    const projectVr = getData?.detail?.[0]?.vr_tour;
    const projectGallary = getData?.detail?.[0]?.gallery;
    const projectVideo = getData?.detail?.[0]?.video;
    const projectMap = getData?.detail?.[0]?.map;
    const meta = getData?.detail?.[0]?.meta;

    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>

            <StyledComponent>
                <BannerDetail
                    page_name={projectDetailsBanner?.page}
                    img={projectDetailsBanner?.image}
                    mimg={projectDetailsBanner?.banner_mimage}
                    title={projectDetailsBanner?.title}
                    project_type={projectDetailsBanner?.project_type}
                    project_location={projectDetailsBanner?.project_location}
                    details_address={projectDetailsBanner?.address}
                    logo={projectDetailsBanner?.logo}
                    detail

                />

                {
                    projectOverview && projectOverview.title && projectOverview.description_left && projectOverview.description_right &&
                        <Overview title={projectDetailsBanner?.title} data={projectOverview}/>
                }
                {
                    projectGlance?.description &&
                    <AtGlance data={projectGlance}/>
                }
                {
                    projectFeature?.feature?.length>0 &&
                    <FeatureSlider  data={projectFeature}/>
                }
                {
                    projectVideo && projectVideo.title && projectVideo.image ?
                        <VideoPopUp vidTitle={projectVideo?.title} detail
                                    vidThumb={projectVideo?.image} vidUrl={projectVideo?.url}/> : ''
                }
                {
                    projectVr?.iframe &&
                    <VirtualTour data={projectVr}/>
                }
                {
                    projectGallary && projectGallary?.title && projectGallary?.image &&
                    <ProjectGallery data={projectGallary}/>
                }
                <ProjectMap data={projectMap}/>
                <ProjectForm title={projectDetailsBanner?.title}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
#gallery{
  .pt-200{
    padding-top: 135px !important;
  }
}
`;

export default MyComponent;
