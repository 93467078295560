import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import CvDrop from "../../components/career/CvDrop";
import AccordionExtra from "../../components/career/AccordionExtra";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/about";
import {fetchCareer} from "../../api/redux/career";
import VideoBanner from "../../components/VideoBanner";
import InnerBannerV2 from "../../components/InnerBannerV2";
import CareerListing from "../../components/career/CareerListing";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CAREER
        dispath(fetchCareer([api_url]))
    }, [])



    let getPost = useSelector(state => state.career)
    const careerBanner = getPost?.posts?.[0]?.banner;
    const careerOverview = getPost?.posts?.[0]?.overview;
    const careerList = getPost?.posts?.[0]?.list;
    const careerLoc = getPost?.posts?.[0]?.filter?.office_location;
    const meta = getPost?.posts?.[0]?.meta;


    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])



    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : '' }`}</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={ meta?.og_desc}/>}
            </Helmet>

            <StyledComponent>
                {/*<InnerBannerV2 page_name={jointBanner?.page}*/}
                {/*               img={jointBanner?.image}*/}
                {/*               mimg={jointBanner?.mimage} title={jointBanner?.title}*/}
                {/*/>*/}
                {/*<InnerBanner img={careerBanner?.image} title={careerBanner?.title} page_name={'Career'}/>*/}
                <VideoBanner img={careerBanner?.image}
                             title={careerBanner?.title}
                             vidUrl={careerBanner?.video_id}
                             page_name={careerBanner?.page}
                             mimg={careerBanner?.mimage}
                />
                <CvDrop data={careerOverview}/>
                <CareerListing data={careerList} loc={careerLoc}/>
                {/*<AccordionExtra data={careerList} loc={careerLoc}/>*/}
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
