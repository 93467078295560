import {createGlobalStyle, css} from 'styled-components';
import {ColorBg, ColorWhite, hover, MidNightBlue, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }
  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDOBlack-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOBlack-Oblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDOBlack.woff2') format('woff2'),
    url('/fonts/OregonLDOBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Black Sinistral';
    src: url('/fonts/OregonLDOBlackSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDOBlackSinistral.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Condensed';
    src: url('/fonts/OregonLDOCondensedBlack-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOCondensedBlack-Oblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDO-Bold.woff2') format('woff2'),
    url('/fonts/OregonLDO-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Book';
    src: url('/fonts/OregonLDOBook-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOBook-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDO-BoldOblique.woff2') format('woff2'),
    url('/fonts/OregonLDO-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Condensed';
    src: url('/fonts/OregonLDOCondensedBlack.woff2') format('woff2'),
    url('/fonts/OregonLDOCondensedBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Book';
    src: url('/fonts/OregonLDOBook.woff2') format('woff2'),
    url('/fonts/OregonLDOBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Book Sinistral';
    src: url('/fonts/OregonLDOBookSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDOBookSinistral.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Condensed';
    src: url('/fonts/OregonLDOCondensed-Bold.woff2') format('woff2'),
    url('/fonts/OregonLDOCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Condensed';
    src: url('/fonts/OregonLDOCondensed-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOCondensed-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Condensed';
    src: url('/fonts/OregonLDOCondensed-BoldOblique.woff2') format('woff2'),
    url('/fonts/OregonLDOCondensed-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDODemiBold-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDODemiBold-Oblique.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO DemiBold Sinistral';
    src: url('/fonts/OregonLDODemiBoldSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDODemiBoldSinistral.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Condensed';
    src: url('/fonts/OregonLDOCondensed.woff2') format('woff2'),
    url('/fonts/OregonLDOCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO ExtraBlack';
    src: url('/fonts/OregonLDOExtraBlack-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOExtraBlack-Oblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Extended';
    src: url('/fonts/OregonLDOExtended-BoldOblique.woff2') format('woff2'),
    url('/fonts/OregonLDOExtended-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Extended';
    src: url('/fonts/OregonLDOExtended-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOExtended-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Extended';
    src: url('/fonts/OregonLDOExtendedBlack.woff2') format('woff2'),
    url('/fonts/OregonLDOExtendedBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDODemiBold.woff2') format('woff2'),
    url('/fonts/OregonLDODemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Extended';
    src: url('/fonts/OregonLDOExtended-Bold.woff2') format('woff2'),
    url('/fonts/OregonLDOExtended-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Extended';
    src: url('/fonts/OregonLDOExtendedBlack-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOExtendedBlack-Oblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Extended';
    src: url('/fonts/OregonLDOExtended.woff2') format('woff2'),
    url('/fonts/OregonLDOExtended.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDOExtraBold-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOExtraBold-Oblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO ExtraBold Sinistral';
    src: url('/fonts/OregonLDOExtraBoldSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDOExtraBoldSinistral.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO ExtraBlack';
    src: url('/fonts/OregonLDOExtraBlack.woff2') format('woff2'),
    url('/fonts/OregonLDOExtraBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDOExtraBold.woff2') format('woff2'),
    url('/fonts/OregonLDOExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO ExtraBlack Sinistral';
    src: url('/fonts/OregonLDOExtraBlackSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDOExtraBlackSinistral.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDOLight-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOLight-Oblique.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }


  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDOMedium-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOMedium-Oblique.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Light Sinistral';
    src: url('/fonts/OregonLDOLightSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDOLightSinistral.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDOLight.woff2') format('woff2'),
    url('/fonts/OregonLDOLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDOMedium.woff2') format('woff2'),
    url('/fonts/OregonLDOMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDO-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDO-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Medium Sinistral';
    src: url('/fonts/OregonLDOMediumSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDOMediumSinistral.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Vanishing';
    src: url('/fonts/OregonLDOVanishingBoldOblique.woff2') format('woff2'),
    url('/fonts/OregonLDOVanishingBoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO UltraBlack';
    src: url('/fonts/OregonLDOUltraBlack-Oblique.woff2') format('woff2'),
    url('/fonts/OregonLDOUltraBlack-Oblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO UltraBlack';
    src: url('/fonts/OregonLDOUltraBlack.woff2') format('woff2'),
    url('/fonts/OregonLDOUltraBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Sinistral';
    src: url('/fonts/OregonLDOSinistral-Bold.woff2') format('woff2'),
    url('/fonts/OregonLDOSinistral-Bold.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO UltraBlack Sinistral';
    src: url('/fonts/OregonLDOUltraBlackSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDOUltraBlackSinistral.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Sinistral';
    src: url('/fonts/OregonLDOSinistral.woff2') format('woff2'),
    url('/fonts/OregonLDOSinistral.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO';
    src: url('/fonts/OregonLDO.woff2') format('woff2'),
    url('/fonts/OregonLDO.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Vanishing';
    src: url('/fonts/OregonLDOVanishingOblique.woff2') format('woff2'),
    url('/fonts/OregonLDOVanishingOblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Vanishing';
    src: url('/fonts/OregonLDOVanishingBold.woff2') format('woff2'),
    url('/fonts/OregonLDOVanishingBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Oregon LDO Vanishing';
    src: url('/fonts/OregonLDOVanishing.woff2') format('woff2'),
    url('/fonts/OregonLDOVanishing.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }




  //typography
  h1 {
    font-family: 'Oregon LDO';
    font-size: 100px;
    line-height: 100px;
  }

  h2 {
    font-family: 'Oregon LDO';
    font-size: 80px;
    line-height: 80px;
    font-weight: 300;
  }

  h3 {
    font-family: 'Oregon LDO';
    font-size: 60px;
    line-height: 72px;
  }

  h4 {
    font-family: 'Oregon LDO';
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
  }

  h5 {
    font-family: 'Oregon LDO';
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
  }

  h6 {
    font-family: 'Oregon LDO';
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }


  ::-webkit-scrollbar {
      width: 0.3rem; /* Adjusted the width to be smaller */
  }

  ::-webkit-scrollbar-track {
      background: transparent;
  }

  ::-webkit-scrollbar-thumb {
      background: ${hover}; /* Color of the thumb */
      border: 1px solid transparent; /* Reduced the border size */
      border-radius: 9px; /* Optionally keep the border-radius */
      background-clip: content-box;
  }

  body {
    font-family: 'Oregon LDO', Arial, Helvetica, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background-color: ${ColorWhite};
    /* Hide scrollbar for Chrome, Safari and Opera */
    //
    //&::-webkit-scrollbar {
    //  display: none;
    //}

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a, h4, h3, h5, h6 {
    color: ${text};
    font-weight: 400;
    margin: 0;
  }

  h1, h2 {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 120px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 120px;
    }
  }

  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }

  .pb-60 {
    padding-bottom: 60px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }

  .pb-40 {
    padding-bottom: 40px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    //opacity: 1 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 99999;
    border: none !important;
    box-shadow: none !important;

    //display: none;

    &.show {
      opacity: 1;

    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #736640;

      &:hover {
      }

      &:after, &:before {
        box-shadow: none;
      }
    }
  }
  
  .menu-fixed .hamburger__inner .logo{
    svg{
      #Path_7008{
        fill: #B2A89F;
      }
      path {
        fill: #FFFFFF;
      }

      line {
        stroke: #FFFFFF !important;
      }

    }
  }
  .menu-fixed .hamburger__inner .hamburger{
    border:1px solid white !important;
    svg{
      path{
        stroke: white !important;
      }
    }
  }
  .menu-fixed .hamburger__inner .hamburger-right svg{
    g{
      stroke: #ffffff !important;
    }
  }
 

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999 !important;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  //.page-loader {
  //  position: fixed;
  //  //background-color: rgb(36, 24, 67);
  //  width: 100vw;
  //  z-index: 999999999;
  //  overflow: hidden;
  //  //opacity: 0;
  //  //height: 100vh;
  //  top: 0;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  //left: 0; //width: 0;
  //  //transition: all 2.6s ease;
  //  //.anim-logo {
  //  //  width: 130px;
  //  //  overflow: hidden;
  //  //  height: fit-content;
  //  //  position: absolute;
  //  //  left: 0;
  //  //  right: 0;
  //  //  top: 0;
  //  //  bottom: 0;
  //  //  opacity: 0;
  //  //  margin: auto;
  //  //  z-index: 2;
  //  //
  //  //  img {
  //  //    height: 55px;
  //  //  }
  //  //}
  //
  //  //.hide-logo {
  //  //  background-color: #010A1A;
  //  //  width: 50%;
  //  //  height: 100vh;
  //  //  top: 0;
  //  //  left: 0;
  //  //  right: 0;
  //  //  margin: auto;
  //  //  position: absolute;
  //  //  z-index: 999;
  //  //}
  //
  //  .pre-loader__img {
  //    //position: fixed;
  //    height: 100px;
  //    width: 100px;
  //    top: 0;
  //    bottom: 0;
  //    left: 0;
  //    right: 0;
  //    background-color: rgb(36, 24, 67);
  //    z-index: 99999999;
  //
  //    //flex: 1;
  //    margin: auto;
  //    opacity: 0;
  //
  //    img {
  //      display: block;
  //    }
  //
  //    .top {
  //      height: 8px;
  //    }
  //
  //    .bottom {
  //      height: 30px;
  //      margin-top: 5px;
  //      animation: flip 1s linear infinite;
  //    }
  //
  //    @keyframes flip {
  //      0% {
  //        transform: rotateY(0deg)
  //      }
  //      100% {
  //        transform: rotateY(180deg)
  //      }
  //    }
  //  }
  //
  //}

  //------------------------animation
  //text animation 
  .split-up {
    text-transform: none;
  }

  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  .faded {
    overflow: hidden;
  }

  //Modal Project Details
  .project-modal {
    padding-left: 0 !important;
    z-index: 99999999;


    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }

    .modal-content {
      background: ${ColorBg};
      color: #181D24;
      padding-bottom: 200px;
    }

    .modal-data__content {
      position: relative;

      h3 {
        color: #897059;
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        border-bottom: 2px solid #736640;
        margin-bottom: 60px;
        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 50px;
        }
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #B2A89F;
        margin-bottom: 40px;
        color: ${MidNightBlue};
        padding-left: 0;

        &::placeholder {
          color: rgba(24, 29, 36, 0.5);
        }
      }

      p {
        color: #897059;
        margin-bottom: 60px;
        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }
    }

    .modal-close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 50px;
      margin-top: 30px;

      svg {
        border-radius: 50%;
        height: 70px;
        width: 70px;
        position: relative;
        cursor: pointer;
        @media (max-width: 767px) {
          height: 50px;
          width: 50px;
        }

        #hover-color {
          fill: transparent;
          transition: fill 0.8s;
        }

        #circle-border {
          transition: all 1s;
        }

        &:hover {
          #hover-color {
            fill: #736640;
            animation: fillAnimation 0.8s forwards;
          }

          #circle-border {
            stroke-width: 0;
          }
        }

        &:not(:hover) {
          #hover-color {
            fill: transparent;
            animation: reverseFillAnimation 0.8s forwards;
          }
        }
      }

      @keyframes fillAnimation {
        0% {
          r: 0;
        }
        100% {
          r: 34.5;
        }
      }
      @keyframes reverseFillAnimation {
        0% {
          r: 34.5;
        }
        100% {
          r: 0;
        }
      }
    }

    .modal-data {
      justify-content: unset;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: ${MidNightBlue};
    }
  }


  //Drop-CV Modal

  .cv-modal {
    padding-left: 0 !important;
    z-index: 999999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }

    .modal-content {
      background: ${ColorBg};
      color: #FFFDFB;
      padding-bottom: 200px;
    }

    .modal-data__content {
      position: relative;

      h3 {
        color: #897059;
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        border-bottom: 2px solid #736640;
        margin-bottom: 60px;
        padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 50px;
        }
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #B2A89F;
        margin-bottom: 40px;
        color: #897059;
        padding-left: 0;

        &::placeholder {
          color: rgba(24, 29, 36, 0.5);
        }
      }

      .form__phoneEmail {
        display: flex;
        gap: 20px;
        @media (max-width: 767px) {
          flex-direction: column;
          input {
            width: 100% !important;
          }
        }

        input {
          width: 49%;
        }
      }

      input[type="textarea"] {
        padding-bottom: 80px;
      }

      .button-group {
        display: flex;
        gap: 40px;
        margin-bottom: 10px;

        .attach-cv {
          width: 222px;
          cursor: pointer;
          border: 2px solid #181D24;
          border-radius: 28px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          label {
            margin-bottom: 0;
            cursor: pointer;
            color: #181D24;
            font-size: 20px;
            line-height: 22px;
            font-weight: bold;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
        }
      }

      .file-name {
        font-size: 14px;
        opacity: 0.8;
        color: #897059;
        padding-left: 10px;
      }

      p {
        color: #897059;
        margin-bottom: 60px;
        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }
    }

    .modal-close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 50px;
      margin-top: 30px;

      svg {
        border-radius: 50%;
        height: 70px;
        width: 70px;
        position: relative;
        cursor: pointer;
        @media (max-width: 767px) {
          height: 50px;
          width: 50px;
        }

        #hover-color {
          fill: transparent;
          transition: fill 0.8s;
        }

        #circle-border {
          transition: all 1s;
        }

        &:hover {
          #hover-color {
            fill: ${ColorWhite};
            animation: fillAnimation 0.8s forwards;
          }

          #circle-border {
            //stroke-width: 0;
            stroke: ${ColorWhite} !important;

          }
        }

        &:not(:hover) {
          #hover-color {
            fill: transparent;
            animation: reverseFillAnimation 0.8s forwards;
          }
        }
      }

      @keyframes fillAnimation {
        0% {
          r: 0;
        }
        100% {
          r: 34.5;
        }
      }
      @keyframes reverseFillAnimation {
        0% {
          r: 34.5;
        }
        100% {
          r: 0;
        }
      }
    }

    .modal-data {
      justify-content: unset;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: ${MidNightBlue};
    }
  }

  //menu fixed
  .scroll-down .main-menu-desktop {
    transform: translate3d(0, -105%, 0);

    &__logo {
      #desktop {
        display: block;
      }

      .desktopUp {
        display: none;
      }
    }
  }

  .scroll-up .main-menu-desktop {
    //background-color: rgba(34, 34, 34, 0.3);
    background-color: rgba(137, 112, 89, 0.8);
    box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);
    
    .b-logo{
      svg{
        #Path_7008{
          fill: #B2A89F !important;
        }
        path{
          fill: white !important;
          
        }
      }
    }

    .nav-list ul li a {
      color: #FFFDFB !important;
      :hover{
        color: rgba(255, 253, 251, 0.6) !important;
      }
    }

    .menu-right-col ul li a {
      color: #FFFDFB !important;

    }

    .menu-right {
      border: 1px solid ${ColorWhite};

      span {
        color: ${ColorWhite};
      }

    }

    .menu-right-col {
      ul {
        li {

          a {
            color: ${ColorWhite};

            :hover {
              color: rgba(255, 253, 251, 0.6) !important;
            }
          }
        }
      }

      .search-bar {
        svg {
          g {
            stroke: ${ColorWhite};
          }
        }
      }
    }

    .hamburger {
      .line {
        background-color: ${ColorWhite};
      }
    }
    &__logo {
      #desktop {
        display: none;
      }

      .desktopUp {
        display: block;
      }
    }
  }


  //Apply Modal

  .apply-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 999999;
      //.modal-open{
      //    .modal{
      //        overflow-y: hidden !important;
      //    }
      //}
      
      .modal-body{
          @media (max-width: 767px) {
              padding-left: 0px !important;
              padding-right: 0px !important;
          }
      }
      
      h5{
          margin-bottom: 35px;
          color: #897059;
      }
      
      .col-md-7{
          margin-top: 120px;
          margin-bottom: 30px;
          @media(max-width: 767px){
              margin-top: 0px;
          }
      }

      .custombar {
          p {
              font-size: 16px;
              line-height: 22px;
              font-weight: 600;
              padding-top: 40px;
              color: ${MidNightBlue};
          }
          
          h6{
              font-size: 28px;
              color: #897059;
          }

          .content{
              padding-bottom: 100px;
              ul {
                  margin-top: 40px;
                  &:first-of-type {
                      margin-top: 0px; // Remove margin-top for the first <ul>
                  }
                  h4 {
                      font-size: 16px;
                      font-weight: bold;
                      margin-bottom: 24px;
                      color: #897059;
                      text-transform: uppercase;
                  }

                  li {
                      font-size: 16px;
                      line-height: 22px;
                      margin-bottom: 10px;
                      position: relative;
                      padding-left: 20px;
                      color: ${MidNightBlue};

                      &:after {
                          position: absolute;
                          content: '';
                          height: 8px;
                          width: 8px;
                          border-radius: 50%;
                          background-color: #B2A89F;
                          left: 0px;
                          top: 8px;
                      }

                      &:nth-last-child(1) {
                          margin-bottom: 0;
                      }
                  }
              }


              @media(max-width: 767px){
                  padding-bottom: 60px;
              }

          }
      }


      @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }
    @media(max-width: 767px){
      .col-md-10{
        padding-right: 0px !important;
      }
      .modal-data__content{
        margin-left: 0px !important;

        
      }
    }

    .modal-content {
      background: ${ColorBg};
      color: ${MidNightBlue};
      //padding-bottom: 50px;
    }

    .modal-data__content {
      position: relative;

      h3 {
        color: #897059;
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        border-bottom: 2px solid #736640;
        margin-bottom: 60px;
        padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 50px;
        }
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #181D24;
        margin-bottom: 30px;
        color: #181D24;
        padding-left: 0;

        &::placeholder {
          color: rgba(24, 29, 36, 0.5);
        }
      }

      .form__phoneEmail {
        display: flex;
        gap: 20px;
        @media (max-width: 767px) {
          flex-direction: column;
          gap: 0px;

          input {
            width: 100% !important;
          }
        }

        input {
          width: 49%;
        }
      }

      input[type="textarea"] {
        padding-bottom: 80px;
      }

      .button-group {
        display: flex;
        gap: 40px;
        margin-bottom: 10px;

        .attach-cv {
          width: 222px;
          cursor: pointer;
          border: 2px solid #897059;
          border-radius: 28px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          label {
            margin-bottom: 0;
            cursor: pointer;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
        }
      }

      .file-name {
        font-size: 14px;
        opacity: 0.8;
        color: #181D24;
        padding-left: 10px;
      }

      p {
        color: #FFFDFB;
        margin-bottom: 60px;
        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }

        @media (max-width: 767px) {
            margin-left: 0px;
        }
    }

    .modal-close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
      margin-top: 10px;
        
        
        &.mobile{
            display: none;
            
        }
        &.desktop-v{
            display: flex;
            justify-content: end;
        }

      svg {
        border-radius: 50%;
        height: 70px;
        width: 70px;
        position: relative;
        cursor: pointer;
        @media (max-width: 767px) {
          height: 50px;
          width: 50px;
        }

        #hover-color {
          fill: transparent;
          transition: fill 0.8s;
        }

        #circle-border {
          transition: all 1s;
        }

        &:hover {
          #hover-color {
            fill: ${ColorWhite};
            animation: fillAnimation 0.8s forwards;
          }

          #circle-border {
            stroke-width: 0;
          }
        }

        &:not(:hover) {
          #hover-color {
            fill: transparent;
            animation: reverseFillAnimation 0.8s forwards;
          }
        }
      }

      @keyframes fillAnimation {
        0% {
          r: 0;
        }
        100% {
          r: 34.5;
        }
      }
      @keyframes reverseFillAnimation {
        0% {
          r: 34.5;
        }
        100% {
          r: 0;
        }
      }
        
        @media(max-width: 767px){
            &.mobile{
                display: flex;
                justify-content: end;
            }
            &.desktop-v{
                display: none;
            }
        }
    }

    .modal-data {
      justify-content: unset;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: #181D24;
    }
  }

  .button {
    width: fit-content;
  }

  .modal_video_popup {
    .modal-content, .modal-video, .modal-dialog {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

    }

    .modal-dialog {
      border: 0 !important;
      margin: 0 !important;
      max-width: unset !important;
      width: 100% !important;
      height: 100% !important;
    }

    .modal-body {
      border: none !important;
      box-shadow: none !important;
    }

    .modal-close {
      opacity: 0;
    }
  }

  .toastify {
    position: fixed;
    z-index: 999999999;
  }

  //page loader
  //.page-loader {
  //  position: fixed;
  //  height: 100%;
  //  width: 100%;
  //  top: 0;
  //  left: 0;
  //  background-color: #181D24;
  //  z-index: 999999999;
  //  display: none;
  //  opacity: 0;
  //  //display: flex;
  //  align-items: center;
  //  justify-content: center;
  //
  //  svg {
  //    height: 80px;
  //    opacity: 0;
  //  }
  //}
  //
  //.door-animation {
  //  position: absolute;
  //  top: 50%;
  //  left: 50%;
  //  width: 100%;
  //  height: 100%;
  //  background-color: #181D24; /* Replace with your desired background color */
  //  transform: translate(-50%, -50%) scaleX(0);
  //  transform-origin: center top;
  //  animation: door-open 2s ease-in-out forwards;
  //}
  //
  //@keyframes door-open {
  //  0% {
  //    transform: translate(-50%, -50%) scaleX(0);
  //  }
  //  50% {
  //    transform: translate(-50%, -50%) scaleX(1);
  //  }
  //  100% {
  //    transform: translate(-50%, -50%) scaleX(0);
  //  }
  //}

  //.page-loader {
  //  //position: relative;
  //  //overflow: hidden;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  z-index: 9999;
  //  overflow: hidden;
  //}
  //page loader
  //.page-loader {
  //  position: fixed;
  //  height: 100%;
  //  width: 100%;
  //  top: 0;
  //  left: 0;
  //  background-color: #897059;
  //  z-index: 999999999;
  //  display: none;
  //  opacity: 0;
  //  //display: flex;
  //  align-items: center;
  //  justify-content: center;
  //
  //  svg {
  //    height: 80px;
  //    opacity: 0;
  //    @media (max-width: 767px) {
  //      margin-left: 5%;
  //    }
  //  }
  //}


  .page-loader1 {
    position: fixed;
    height: 100%;
    width: 50%;
    top: 0;
    left: -100%; /* Start off-screen to the left */
    background-color: #897059;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s; /* Add a transition for smoother opacity changes */
  }
  .page-loader1 {
    position: fixed;
    height: 100%;
    width: 50%;
    top: 0;
    left: -100%; /* Start off-screen to the left */
    background-color: #897059;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s; /* Add a transition for smoother opacity changes */
  }
  
  
  
  
  
  
  
  
  
  
  
  //
  //.desktop-logo img{
  //  z-index: 999999;
  //}
  //.desktop-logo img{
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  right:0;
  //  margin:auto;
  //  bottom:0;
  //}
  //
  //.desktop-logo svg{
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  right:0;
  //  margin:auto;
  //  bottom:0;
  //  display: block;
  //}


  //
  //.left-door,.right-door
  //{
  //  position: fixed;
  //  top: 0;
  //  bottom: 0;
  //  width: 50%;
  //  height: 100%;
  //  background-color: rgba(137, 112, 89); /* Replace with your desired background color  rgba(24, 29, 36, 0.7) */
  //  z-index: 1;
  //}
  //
  //.left-door {
  //  left: 0;
  //}
  //
  //.right-door {
  //  right: 0;
  //  //transform-origin: right;
  //}

  //@keyframes door-open {
  //  0% {
  //    transform: scaleX(1);
  //  }
  //  50% {
  //    transform: scaleX(0);
  //  }
  //  100% {
  //    transform: scaleX(0);
  //  }
  //}


  .modal-data {
    z-index: 9999999 !important;

    .modal-video {
      z-index: 999999999;
    }
  }
  #smooth-content{
    will-change: transform !important;
  }
  .modal{
      overflow-y: hidden !important;
      @media (max-width: 767px) {
          overflow-y: auto !important;
          
      }
  }



  //page loader
  .page-loader {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #897059;
    z-index: 999999999;
    display: none;
    opacity: 0;
    //display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 80px;
      opacity: 0;
      @media (max-width: 767px) {
        margin-left: 5%;
      }
    }
  }
  
  


`;



